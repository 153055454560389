@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    
}

.navigate header {
    @apply lg:!px-0 
}